import React from "react";

import { Box, Header, PseudoBox, Flex } from "Atoms";
import { CloseButton } from "Molecules";
import { ReloadPage } from "./";

type Props = {
    needToRefresh: boolean;
    onToggle?: () => void;
};

export const BackOfficeNavigationHeader: React.FC<Props> = ({ needToRefresh, onToggle }) => (
    <Box pb={[0, 4]} position="relative">
        <Flex alignItems="center" gridGap={2}>
            <PseudoBox
                _hover={{ cursor: "pointer" }}
                display="flex"
                h="auto"
                justifyContent="center"
                alignItems="center"
                size="50px"
                rounded="lg"
                bg="newPrimary"
                color="newPrimaryFont"
                onClick={onToggle}
            >
                <Header fontFamily="qopla" userSelect="none">
                    Q
                </Header>
            </PseudoBox>
            {needToRefresh && <ReloadPage />}
        </Flex>
        <CloseButton display={{ base: "none", lg: "block" }} onClick={onToggle} />
    </Box>
);
