import React from "react";
import { Link as RLink } from "react-router-dom";
import { FiLogOut } from "@react-icons/all-files/fi/FiLogOut";
import { BsChevronExpand } from "@react-icons/all-files/bs/BsChevronExpand";

import { Box, Stack, Text, Link, Collapsable, PseudoBox, Avatar, Flex, Emoji, Button } from "Atoms";
import { useLanguage, useModal, useQopla } from "Providers";
import { useSwitch } from "Hooks";
import { modalNames } from "Constants";
import { FlagLanguagePicker } from "Molecules";
import { LinkType } from "Types";

export const BackOfficeNavigationFooter: React.FC<any> = props => {
    const { translate } = useLanguage();
    const { authenticatedUser } = useQopla();
    const { openModal } = useModal();

    const { open, onToggle } = useSwitch();

    const languagePickWrapper = {
        borderColor: "gray.200",
        borderWidth: "0.3rem",
        borderStyle: "solid",
        width: "100%",
        roundedTopLeft: "lg",
        roundedTopRight: "lg"
    };

    return (
        <Box w="full" mt={5} pr={[0, 4]}>
            <Collapsable open={open}>
                <FlagLanguagePicker wrapperProps={languagePickWrapper} p={3} />
                <Stack bg="gray.200" stretch={3} mb={3} py={2} px={3} roundedBottomLeft="lg" roundedBottomRight="lg">
                    <PseudoBox
                        as="span"
                        onClick={() => {
                            props.onToggleEditMode();
                            onToggle();
                        }}
                        _hover={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                        {translate("customiseNavigation")}
                    </PseudoBox>
                    <Link as={RLink as LinkType} to="/admin/releaseNotes">
                        {translate("releaseNotes")}
                    </Link>
                    <Link as={RLink as LinkType} to="/admin/manual">
                        {translate("helpPage")}
                    </Link>
                    <PseudoBox
                        as="span"
                        onClick={() => openModal(modalNames.ABOUT_QOPLA_MODAL)}
                        _hover={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                        {translate("about")}
                    </PseudoBox>
                    <Link as={RLink as LinkType} to="/logout" data-test="button-logout">
                        {translate("logOut")}
                    </Link>
                </Stack>
            </Collapsable>
            <Stack w="full" h="auto">
                <PseudoBox
                    _hover={{ bg: "gray.200", cursor: "pointer" }}
                    transition="all 0.3s"
                    py={2}
                    pr={1}
                    rounded="lg"
                    display="flex"
                    alignItems="center"
                    data-test="nav-user-footer"
                    onClick={onToggle}
                >
                    <Stack isInline stretch={4} flex="1">
                        <Avatar name={authenticatedUser.fullName || (authenticatedUser.username as string)} />
                        <Box color="blue.900" fontWeight="700">
                            {authenticatedUser.fullName ? (
                                <>
                                    <Text fontSize="lg" mb={2}>
                                        {authenticatedUser.fullName}
                                    </Text>
                                    <Text fontSize="sm" lineHeight="shorter">
                                        {authenticatedUser.username}
                                    </Text>
                                </>
                            ) : (
                                <Text fontSize="lg">{authenticatedUser.username}</Text>
                            )}
                        </Box>
                    </Stack>
                    <Box as={BsChevronExpand} size="25px" color="gray.600" />
                </PseudoBox>
            </Stack>
        </Box>
    );
};
