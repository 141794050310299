import React from "react";

import { BaseBoxProps, Box } from "Atoms";

type Props = BaseBoxProps;

export const BackOfficeContainer: React.FC<Props> = props => (
    <Box maxH="100vh" overflow="auto" bg="gray.50">
        <Box w="100%" marginX="auto" maxW="container.2xl" p={4} pt={{ base: 16, lg: 4 }}>
            {props.children}
        </Box>
    </Box>
);
