import moment from "moment";

import { BackOfficeNavigationItems } from "../BackOfficeNavigation";
import { BackOfficeSideNavigationValidity } from "../components/BackOfficeNavigationBody";

/*
    shouldShowRoute calls each function in backOfficeNavigationItems.ts
*/
export const shouldShowRoute = (
    condition: Function | undefined,
    backOfficeSideNavigationValidity: BackOfficeSideNavigationValidity
) => (typeof condition === "function" ? condition(backOfficeSideNavigationValidity) : true);

/*
    NOTE: in test suite
*/
export const getBackOfficeNavigationItems = (
    backOfficeNavigationItems: BackOfficeNavigationItems,
    backOfficeSideNavigationValidity: BackOfficeSideNavigationValidity,
    navigationSortOrder: string[]
) => {
    return backOfficeNavigationItems
        .reduce<BackOfficeNavigationItems>((acc, backOfficeNavigationItem) => {
            const shouldViewNavigationItem = shouldShowRoute(
                backOfficeNavigationItem.showWhen,
                backOfficeSideNavigationValidity
            );

            // Hide this nav item
            if (!shouldViewNavigationItem) {
                return acc;
            }

            // Filter children to be shown
            const _children = backOfficeNavigationItem.children
                .filter(child => shouldShowRoute(child.showWhen, backOfficeSideNavigationValidity))
                .map(child => {
                    if (!!child.subChildren?.length) {
                        const updateSubChildren = child.subChildren.filter(subChild => {
                            return shouldShowRoute(subChild.showWhen, backOfficeSideNavigationValidity);
                        });
                        return { ...child, subChildren: updateSubChildren };
                    } else return child;
                });

            // Show this nav item
            acc.push({
                ...backOfficeNavigationItem,
                children: _children
            });

            return acc;
        }, [])
        .sort(
            (a, b) =>
                navigationSortOrder?.findIndex(order => order === a.groupTitle) -
                navigationSortOrder?.findIndex(order => order === b.groupTitle)
        );
};

/*
Function to check if we should logout inactive user.
User is inactive if session has expired.

BuffertTime is being used so we dont get logged out immediately
*/
export const shouldLogoutBySessionTTL = (userSessionTTL: number) => {
    const buffertTime = 5;
    const now = moment().unix();

    const lastContactWithBackend = parseInt(sessionStorage.getItem("lastContactWithBackend") ?? "");
    const ttlTimeoutSeconds = parseInt((userSessionTTL / 1000).toFixed(0));
    if (lastContactWithBackend) {
        const sessionTTL = lastContactWithBackend + ttlTimeoutSeconds - (now + buffertTime);
        const shouldLogout = sessionTTL < buffertTime;

        if (shouldLogout) {
            return true;
        }
    }

    return false;
};
