import React from "react";

import { Box, Flex } from "Atoms";
import { CateringContactInformation, CateringProducts } from ".";
import { SwishOrderResponse } from "../../../customer/pages/orderStatus/OrderStatusContainer";
import { CateringPayment } from "./CateringPayment";

type Props = {
    order: SwishOrderResponse;
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringPrivateSelfPickup: React.FC<Props> = ({
    order,
    displayTotalPrice = false,
    canSelectPrices = true
}) => {
    const {
        userInformation: { onlineContactInformation },
        orderProducts
    } = order;
    const refPhone = onlineContactInformation.phoneNumber2 || onlineContactInformation.phoneNumber;

    return (
        <Box>
            <Flex direction="column">
                <CateringContactInformation
                    refName={onlineContactInformation.name}
                    refPhone={refPhone}
                    refEmail={onlineContactInformation.email}
                    isFullWidth
                />
                <CateringProducts
                    orderProducts={orderProducts}
                    displayTotalPrice={displayTotalPrice}
                    canSelectPrices={canSelectPrices}
                />
                <CateringPayment paymentMethodAmounts={order.paymentMethodAmounts} />
            </Flex>
        </Box>
    );
};
