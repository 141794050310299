import React from "react";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";

import { Box, Checkbox, Flex, Stack, Text } from "Atoms";
import { IconButton } from "Molecules";
import { useSwitch } from "Hooks";

type MenuProduct = {
    id: string;
    refProduct: {
        id: string;
        name: string;
    };
};

type MenuBundleProduct = {
    id: string;
    refBundleProduct: {
        id: string;
        name: string;
    };
};

export interface IMenuProductCategories {
    id: string;
    name: string;
    menuProducts: MenuProduct[];
    menuBundleProducts: MenuBundleProduct[];
}

type Props = {
    menuProductCategories: IMenuProductCategories[];
    selectedCategoryIds: string[];
    selectedProductIds: string[];
    onToggleCategory: (categoryId: string, isChecked: boolean) => void;
    onToggleProduct: (productId: string, isChecked: boolean) => void;
    alcoholProductIds: string[];
    isDisabled: boolean;
    showHasProductsMessage?: boolean;
};

const getRefProductOrRefBundleProduct = (menuProduct: MenuProduct | MenuBundleProduct) => { 
    return "refProduct" in menuProduct ? menuProduct.refProduct : menuProduct.refBundleProduct;
}

export const MenuProductsAndCategoriesTreeList: React.FC<Props> = ({
    menuProductCategories,
    selectedCategoryIds,
    selectedProductIds,
    onToggleCategory,
    onToggleProduct,
    alcoholProductIds,
    isDisabled,
    showHasProductsMessage = false
}) => {
    const hasAlcoholProducts = !!alcoholProductIds.length;

    return (
        <Stack stretch={8}>
            {menuProductCategories.map(category => {
                const { open, onToggle } = useSwitch();
                const icon = open ? FaChevronUp : FaChevronDown;
                const isCategoryChecked = selectedCategoryIds.includes(category.id);

                let haveProductsBeenChecked: boolean = false;
                if (showHasProductsMessage) {
                    haveProductsBeenChecked =
                        [...category.menuProducts, ...category.menuBundleProducts].some(menu =>
                            selectedProductIds.includes(menu.id)
                        ) ?? false;
                }

                const displayMessage = haveProductsBeenChecked && !open;
                const menuProducts = [...category.menuProducts, ...category.menuBundleProducts];
                const categoryContainsAlcohol = !hasAlcoholProducts
                    ? false
                    : menuProducts?.some(menuProduct => {
                          const product = getRefProductOrRefBundleProduct(menuProduct);
                          return alcoholProductIds.includes(product.id);
                      });

                return (
                    <Box key={category.id}>
                        <Stack isInline stretch={2} align="center">
                            <IconButton icon={icon} type="button" size="sm" onClick={onToggle} />
                            <Checkbox
                                onChange={() => onToggleCategory(category.id, isCategoryChecked)}
                                checked={isCategoryChecked}
                                disabled={categoryContainsAlcohol || isDisabled}
                            />
                            <Flex>
                                {displayMessage ? (
                                    <>
                                        <Text fontSize="lg" marginBottom={0}>
                                            {category.name}
                                        </Text>
                                        <Text fontSize="sm" fontStyle="italic" color="orange.500">
                                            {" ** Har produkter"}
                                        </Text>
                                    </>
                                ) : (
                                    <Text fontSize="lg">{category.name}</Text>
                                )}
                            </Flex>
                        </Stack>
                        {open && (
                            <Stack mx={12} mt={4}>
                                {menuProducts.map((menuProduct, index) => {
                                    const product = getRefProductOrRefBundleProduct(menuProduct);
                                    const isProductChecked = selectedProductIds.includes(menuProduct.id);
                                    const refId = product.id;
                                    const isAlcoholProduct = !hasAlcoholProducts
                                        ? false
                                        : alcoholProductIds.includes(refId);
                                    return (
                                        <Flex key={index}>
                                            <Checkbox
                                                disabled={isAlcoholProduct || isCategoryChecked || isDisabled}
                                                onChange={() => onToggleProduct(menuProduct.id, isProductChecked)}
                                                checked={isProductChecked}
                                            >
                                                {product.name}
                                            </Checkbox>
                                        </Flex>
                                    );
                                })}
                            </Stack>
                        )}
                    </Box>
                );
            })}
        </Stack>
    );
};
