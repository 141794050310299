import React from "react";

import { Flex } from "Atoms";
import { CateringContactInformation, CateringDeliveryInformation, CateringProducts } from ".";
import { SwishOrderResponse } from "../../../customer/pages/orderStatus/OrderStatusContainer";
import { CateringPayment } from "./CateringPayment";
import { CateringDeliveryFee } from "./CateringDeliveryFee";

type Props = {
    order: SwishOrderResponse;
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringPrivateDelivery: React.FC<Props> = ({
    order,
    displayTotalPrice = false,
    canSelectPrices = true
}) => {
    const {
        userInformation: { onlineContactInformation },
        orderProducts
    } = order;
    const refPhone = onlineContactInformation.phoneNumber2 || onlineContactInformation.phoneNumber;

    return (
        <Flex direction="column" height="auto">
            <Flex justify="space-between" height="auto">
                <CateringContactInformation
                    refName={onlineContactInformation.name}
                    refPhone={refPhone}
                    refEmail={onlineContactInformation.email}
                    isFullWidth
                />
            </Flex>
            <CateringDeliveryInformation order={order} />
            <CateringProducts
                orderProducts={orderProducts}
                displayTotalPrice={displayTotalPrice}
                canSelectPrices={canSelectPrices}
            />
            <CateringDeliveryFee orderProducts={orderProducts} />
            <CateringPayment paymentMethodAmounts={order.paymentMethodAmounts} />
        </Flex>
    );
};
