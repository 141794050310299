import React from "react";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Collapsable, Label, PseudoBox, Stack, Text } from "Atoms";
import { useSwitch } from "Hooks";
import { IconButton } from "Molecules";
import { BackOfficeNavigationItem as BackOfficeNavigationItemType } from "../BackOfficeNavigation";
import { BackOfficeNavigationItem } from "./";
import { useLanguage } from "Providers";
import { useQoplaStore } from "Stores";

type Props = {
    backOfficeNavigationItem: BackOfficeNavigationItemType;
    isExpanded: boolean;
    unhandledInvoiceNumber: number;
    onToggleOpenNavigationGroup: (groupTitle: string) => void;
};

export const BackOfficeNavigationGroup: React.FC<Props> = ({
    backOfficeNavigationItem,
    isExpanded,
    unhandledInvoiceNumber,
    onToggleOpenNavigationGroup,
    ...rest
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { translate } = useLanguage();
    const { selectedShop } = useQoplaStore();
    const shouldShowUnhandeledInvoices =
        !!selectedShop?.id && backOfficeNavigationItem.groupTitle == "Faktura" && pathname != "/admin/invoices";

    const hasTranslationKey = !!backOfficeNavigationItem.translationKey;
    const groupTitle = hasTranslationKey
        ? translate(backOfficeNavigationItem?.translationKey!)
        : backOfficeNavigationItem.groupTitle;

    const { open, onToggle } = useSwitch(isExpanded);

    return (
        <Stack w="full" h="auto" stretch={12} {...rest}>
            <Box>
                <PseudoBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    _hover={{ cursor: "pointer" }}
                    mb={3}
                    data-test={`nav-group-${backOfficeNavigationItem.groupTitle}`}
                    onClick={() => {
                        onToggle();
                        onToggleOpenNavigationGroup(backOfficeNavigationItem.groupTitle);
                    }}
                >
                    <Text
                        m="0"
                        fontSize="sm"
                        fontWeight="bold"
                        textTransform="uppercase"
                        letterSpacing="1px"
                        color="gray.800"
                    >
                        {groupTitle}
                    </Text>
                    {shouldShowUnhandeledInvoices && (
                        <Label
                            size="sm"
                            themeColor={unhandledInvoiceNumber > 0 ? "orange" : "gray"}
                            onClick={() => {
                                navigate("/admin/invoices", { state: { shouldShowUnhandled: true } });
                            }}
                        >
                            {unhandledInvoiceNumber}
                        </Label>
                    )}
                    <IconButton variant="ghost" size="sm" icon={open ? FaChevronUp : FaChevronDown} />
                </PseudoBox>
                <Collapsable open={open}>
                    <Stack w="full" stretch={4}>
                        {backOfficeNavigationItem.children.map(children => {
                            const isActive = children.to === pathname;
                            const hasTranslationKey = !!children.translationKey;
                            children.title = hasTranslationKey ? translate(children.translationKey!) : children.title;
                            const subChildren = children.subChildren ?? [];

                            return (
                                <BackOfficeNavigationItem
                                    key={children.to}
                                    isActive={isActive}
                                    subChildren={subChildren}
                                    pathname={pathname}
                                    {...children}
                                />
                            );
                        })}
                    </Stack>
                </Collapsable>
            </Box>
        </Stack>
    );
};
