import React from "react";
import { useQuery } from "@apollo/react-hooks";

import { Modal, ModalHeader, ModalActions, ModalBody, ModalCloseBtn } from "Molecules";
import { IModalContext, useQopla } from "Providers";
import { Box, Skeleton, Stack, Text } from "Atoms";
//@ts-ignore
import packageJson from "../../../../package.json";
import { envConstants } from "Constants";
import { GET_CONTROL_UNIT_SERIAL } from "GraphQLQueries";

export const AboutQoplaModal: React.FC<IModalContext<{}>> = ({ closeModal }) => {
    const { selectedPos } = useQopla();

    const onClose = () => closeModal("aboutQoplaModal");

    const { data, loading } = useQuery(GET_CONTROL_UNIT_SERIAL, {
        variables: { posId: selectedPos?.id },
        skip: !selectedPos?.id
    });

    return (
        <Modal open onClose={onClose} closeOnEscape overlayProps={{ zIndex: 1400 }}>
            <ModalHeader>Om Qopla</ModalHeader>
            <ModalCloseBtn onClick={onClose} />
            <ModalBody>
                <Stack stretch={2}>
                    <Box>
                        <Text m={1}>Kassan och administrationsgränssnittet är tillverkat av:</Text>
                        <Box ml={3}>
                            <Text fontWeight="bold">Qopla AB </Text>
                            <Text fontWeight="bold">559148-4331</Text>
                        </Box>
                    </Box>
                    <Box>
                        <Text>
                            <Text as="span">Modellbeteckning: </Text>
                            <Text as="span" fontWeight="bold">
                                {packageJson.version}
                            </Text>
                        </Text>
                        <Text>
                            <Text as="span">Version: </Text>
                            <Text as="span" fontWeight="bold">
                                {envConstants.VERSION ? envConstants.VERSION : packageJson.version}
                            </Text>
                        </Text>
                        {loading && (
                            <>
                                <Skeleton h="30px" w="100%" />
                                <Skeleton h="30px" w="100%" />
                            </>
                        )}
                        {selectedPos && data?.getControlUnitSerial && (
                            <>
                                <Text>
                                    <Text as="span">Kontrollenhet: </Text>
                                    <Text as="span" fontWeight="bold">
                                        {data.getControlUnitSerial}
                                    </Text>
                                </Text>
                                <Text>
                                    <Text as="span">Tillverkningsnummer: </Text>
                                    <Text as="span" fontWeight="bold">
                                        {selectedPos.posControlUnitValues.productionNumber}
                                    </Text>
                                </Text>
                            </>
                        )}
                    </Box>
                </Stack>
            </ModalBody>
            <ModalActions display="flex" justifyContent="flex-end">
                <Text fontSize="lg">
                    <Text as="span">Powered by </Text>
                    <Text as="span" fontFamily="qopla">
                        Qopla
                    </Text>
                </Text>
            </ModalActions>
        </Modal>
    );
};
