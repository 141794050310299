import React from "react";

import { Text } from "Atoms";

type Props = {
    title: string;
    value: string;
};

export const CateringBoldAndLightText: React.FC<Props> = ({ title, value }) => (
    <Text fontSize="lg">
        <Text as="span" fontWeight="bold">
            {title}:
        </Text>
        <Text as="span"> {value}</Text>
    </Text>
);
