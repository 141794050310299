import React from "react";

import { Box, Flex, PseudoBox, PseudoBoxProps } from "Atoms";

type Props = PseudoBoxProps;

const BOCard: React.FC<Props> = props => (
    <PseudoBox
        boxShadow="md"
        border="1px solid #E2E8F0"
        bg="white"
        rounded="md"
        display="flex"
        flexDirection="column"
        {...props}
    />
);
const BOCardHeader: React.FC<Props> = props => <PseudoBox fontSize="xl" fontWeight="bold" my={2} {...props} />;
const BOCardBody: React.FC<Props> = props => <PseudoBox p={4} flex="1" {...props} />;
const BOCardFooter: React.FC<Props> = props => (
    <Flex p={4} direction={["column", "row"]} borderTop="1px solid #E2E8F0" {...props} />
);

export { BOCard, BOCardHeader, BOCardBody, BOCardFooter };
