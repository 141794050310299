import React, { useEffect } from "react";
import { IconType } from "@react-icons/all-files";
import { useNavigate, useLocation } from "react-router-dom";
import { useMedia } from "react-media";

import { Box, Flex } from "Atoms";
import { useInterval, useSwitch } from "Hooks";
import {
    Drawer,
    DrawerActions,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay
} from "Organisms";
import { Languagekey, useLocalPosSettings, useQopla } from "Providers";
import {
    BackOfficeNavigationHeader,
    BackOfficeNavigationBody,
    BackOfficeNavigationFooter,
    BackOfficeNavigationHamburgerButton,
    ReloadPage
} from "./components";
import { shouldLogoutBySessionTTL } from "./utils/backOfficeNavigationUtils";

export type BackOfficeNavigationChild = {
    title: string;
    translationKey?: Languagekey;
    to: string;
    icon: IconType;
    showWhen?: any;
    subChildren?: BackOfficeNavigationSubChild[];
};

export type BackOfficeNavigationSubChild = { isDynamicLink?: boolean } & BackOfficeNavigationChild;

type BackOfficeNavigationChildren = BackOfficeNavigationChild[];

export type BackOfficeNavigationItem = {
    groupTitle: string;
    translationKey?: Languagekey;
    showWhen?: any;
    children: BackOfficeNavigationChildren;
};

export type BackOfficeNavigationItems = BackOfficeNavigationItem[];

const collapsedRoutes = [
    "/admin/pos",
    "/admin/kitchenDisplay",
    "/admin/orderDisplay",
    "/admin/pickupDisplay",
    "/admin/view-catering-order",
    "/admin/components"
];

const hiddenRoutes = ["/admin/expressCheckout", "/admin/expressPosContainer"];

export const BackOfficeNavigation = () => {
    const isSmallScreen = useMedia({ query: "(max-width: 991px)" });
    const { open: isInEditMode, onToggle: onToggleEditMode, onClose: onCloseEditMode } = useSwitch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { userSessionTTL, needToRefresh } = useQopla();
    const hasLocalSettings = useLocalPosSettings(true);

    let adjustedHiddenRoutes = hiddenRoutes;
    if (hasLocalSettings && hasLocalSettings.disableOnLockingPos) {
        adjustedHiddenRoutes.push("/admin/pos");
    } else if (hasLocalSettings && !hasLocalSettings.disableOnLockingPos) {
        adjustedHiddenRoutes = adjustedHiddenRoutes.filter((value: string) => value !== "/admin/pos");
    }

    const shouldCollapseSideBar = collapsedRoutes.includes(pathname);
    const shouldHideSideBar = adjustedHiddenRoutes.includes(pathname);
    const shouldShowSidebar = !(isSmallScreen || shouldCollapseSideBar || shouldHideSideBar);

    const { open, onClose, onToggle } = useSwitch(shouldShowSidebar);

    useEffect(() => {
        if (isSmallScreen) {
            onClose();
        }
    }, [pathname]);

    useInterval(() => {
        const _shouldLogoutBySessionTTL = shouldLogoutBySessionTTL(Number(userSessionTTL));

        _shouldLogoutBySessionTTL && navigate("/logout");
    }, 10000);

    return (
        <>
            {
                <>
                    <BackOfficeNavigationHamburgerButton
                        onToggle={onToggle}
                        isOpen={open}
                        shouldDisplayArrowIcon={shouldCollapseSideBar}
                        shouldHideSideBar={shouldHideSideBar}
                    />
                    {needToRefresh && !shouldCollapseSideBar && (
                        <Box position="absolute" left="70px" top="18px" w="350px">
                            <ReloadPage />
                        </Box>
                    )}
                </>
            }
            {open &&
                (!isSmallScreen ? (
                    <Box
                        w="22rem"
                        bg="white"
                        color="black"
                        fontSize="sm"
                        background="gray.100"
                        borderRightColor="gray.200"
                        borderRightStyle="solid"
                        borderRightWidth="2px"
                        zIndex={1100}
                        maxH="100vh"
                        top="0"
                        left="0"
                        bottom="0"
                        userSelect="none"
                    >
                        <Flex direction="column" height="full" py={4} pl={5}>
                            <BackOfficeNavigationHeader
                                onToggle={onToggle}
                                needToRefresh={needToRefresh && !shouldCollapseSideBar}
                            />
                            <BackOfficeNavigationBody isInEditMode={isInEditMode} onCloseEditMode={onCloseEditMode} />
                            <BackOfficeNavigationFooter onToggleEditMode={onToggleEditMode} />
                        </Flex>
                    </Box>
                ) : (
                    <Drawer open={open} size="sm" from="left" isScrolling onClose={onClose}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton zIndex={10} />
                            <DrawerHeader pb="0">
                                <BackOfficeNavigationHeader needToRefresh={needToRefresh && !shouldCollapseSideBar} />
                            </DrawerHeader>
                            <DrawerBody maxH="auto" pb="0">
                                <BackOfficeNavigationBody
                                    isInEditMode={isInEditMode}
                                    onCloseEditMode={onCloseEditMode}
                                />
                            </DrawerBody>
                            <DrawerActions pt="0">
                                <BackOfficeNavigationFooter onToggleEditMode={onToggleEditMode} />
                            </DrawerActions>
                        </DrawerContent>
                    </Drawer>
                ))}
        </>
    );
};
