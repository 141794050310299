import React from "react";

import { Flex, Text, BaseBoxProps } from "Atoms";

type Props = {
    title: string;
    address: string;
    postCode: string | number;
    district: string;
} & BaseBoxProps;

export const CateringAddressContent: React.FC<Props> = ({ title, address, postCode, district, ...rest }) => {
    const postCodeWithDistrict = `${postCode}, ${district}`;
    return (
        <Flex direction="column" height="auto" {...rest}>
            <Text fontSize="lg" fontWeight="bold" mb={1}>
                {title}:
            </Text>
            <Flex direction="column" ml={4}>
                <Text as="span" fontSize="lg">
                    {address}
                </Text>
                <Text as="span" fontSize="lg">
                    {postCodeWithDistrict}
                </Text>
            </Flex>
        </Flex>
    );
};
