import React from "react";

import { Flex, Header } from "Atoms";
import { CateringBoldAndLightText, CateringAddressContent } from ".";
import { InvoiceData } from "Types";

type Props = {
    invoiceData: InvoiceData;
    phoneNumber: string;
};

export const CateringCompanyInformation: React.FC<Props> = ({ invoiceData, phoneNumber }) => {
    const { invoiceAddress } = invoiceData;
    return (
        <Flex direction="column" bg="gray.200" p={4} rounded="lg" width={["100%", "100%", "50%"]}>
            <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                Företagsuppgifter
            </Header>
            <Flex direction="column" height="auto">
                <CateringBoldAndLightText title="Företagsnamn" value={invoiceData.invoiceAddress.name} />
                <CateringBoldAndLightText title="Organisationsnummer" value={invoiceData.organisationNumber} />
                <Flex direction="column">
                    <CateringBoldAndLightText title="Telefonnummer" value={phoneNumber} />
                    <CateringAddressContent
                        title="Fakturerings adress"
                        address={invoiceAddress.addressLine}
                        postCode={invoiceAddress.postCode}
                        district={invoiceAddress.city}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
