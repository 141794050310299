import React from "react";
import { GiHamburgerMenu } from "@react-icons/all-files/gi/GiHamburgerMenu";
import { RiArrowLeftSLine } from "@react-icons/all-files/ri/RiArrowLeftSLine";
import { RiArrowRightSLine } from "@react-icons/all-files/ri/RiArrowRightSLine";
import { IconButton } from "Molecules";

type Props = {
    shouldHideSideBar: boolean;
    onToggle: () => void;
    shouldDisplayArrowIcon: boolean;
    isOpen: boolean;
};

export const BackOfficeNavigationHamburgerButton: React.FC<Props> = ({
    shouldHideSideBar,
    onToggle,
    shouldDisplayArrowIcon,
    isOpen
}) => {
    let styles = {};

    if (shouldDisplayArrowIcon) {
        styles = {
            right: 0,
            top: 0,
            variant: "ghost",
            icon: isOpen ? RiArrowLeftSLine : RiArrowRightSLine,
            size: "sm",
            color: "gray.500",
            fontSize: "2rem",
            _hover: {}
        };
    } else {
        styles = {
            left: 5,
            variant: "solid",
            icon: GiHamburgerMenu,
            size: "lg"
        };
    }

    return (
        <>
            {!shouldHideSideBar && (
                <IconButton
                    icon={(styles as any).icon}
                    position="absolute"
                    top={4}
                    data-test="show-navigation"
                    zIndex={10}
                    onClick={onToggle}
                    rounded="lg"
                    {...styles}
                />
            )}
        </>
    );
};
