import React from "react";

import { Flex, Header } from "Atoms";
import { CateringAddressContent, CateringBoldAndLightText } from ".";
import { SwishOrderResponse } from "../../../customer/pages/orderStatus/OrderStatusContainer";

type Props = {
    order: SwishOrderResponse;
};

export const CateringDeliveryInformation: React.FC<Props> = ({ order }) => {
    const {
        invoiceData,
        userInformation: { onlineContactInformation }
    } = order;

    let district = "";
    if (order.invoiceData) {
        district = invoiceData.invoiceAddress.city;
    } else if (onlineContactInformation.addressLine) {
        district = onlineContactInformation.city;
    }

    return (
        <Flex direction="column" bg="gray.200" p={4} rounded="lg" mt={[0, 0, 8]} height="auto">
            <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                Leveransuppgifter
            </Header>
            <Flex direction="column">
                <CateringAddressContent
                    title="Leverans adress"
                    address={onlineContactInformation.addressLine}
                    postCode={onlineContactInformation.postCode}
                    district={district}
                    mb={4}
                />
                {!!onlineContactInformation.doorCode && (
                    <CateringBoldAndLightText title="Portkod" value={onlineContactInformation.doorCode} />
                )}
                {!!onlineContactInformation.floorNumber && (
                    <CateringBoldAndLightText title="Våning" value={onlineContactInformation.floorNumber} />
                )}
            </Flex>
        </Flex>
    );
};
