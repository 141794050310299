import React from "react";

import { NewGrid as Grid, Skeleton } from "Atoms";

export const BackOfficeLoadingCards = () => (
    <Grid templateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]} gap={8}>
        <Skeleton h="200px" w="100%" />
        <Skeleton h="200px" w="100%" />
        <Skeleton h="200px" w="100%" />
        <Skeleton h="200px" w="100%" />
        <Skeleton h="200px" w="100%" />
        <Skeleton h="200px" w="100%" />
    </Grid>
);
