import React from "react";
import Select, { ActionMeta, ValueType } from "react-select";

import { FormLabel } from "Atoms";
import { SelectOption } from "Molecules";
import { Languagekey, useLanguage } from "Providers";
import { CountryLocales, CountryName, CountryLocale } from "Types";

type Props = {
    localeValue: CountryLocale;
    fieldName: string;
    setFieldValue: (fieldName: string, fieldValue: CountryLocale) => void;
};

export const CompanyLocaleSelect: React.FC<Props> = ({ localeValue, fieldName, setFieldValue }) => {
    const { translate } = useLanguage();

    const selectedLocales: SelectOption[] = Object.entries(CountryLocales).map((entries: [string, CountryLocale]) => {
        const [country, locale] = entries;
        const countryTranslation = translate(country as Languagekey);
        return {
            label: `${countryTranslation} - ${locale.currencyCode}`,
            value: country
        };
    });

    const getDefaultLocale = (locale: CountryLocale) => {
        const findCountryLocale = Object.entries(CountryLocales)?.find((entries: [string, CountryLocale]) => {
            const [_, entryLocale] = entries;
            return entryLocale.localeCode === locale.localeCode;
        }) ?? [CountryName.SWEDEN, CountryLocales.SWEDEN];

        const [name, countryLocale] = findCountryLocale;
        const defaultLocale: SelectOption = {
            label: `${translate(name as CountryName)} - ${countryLocale.currencyCode}`,
            value: countryLocale.localeCode
        };
        return defaultLocale;
    };
    return (
        <>
            <FormLabel>{translate("currency")}</FormLabel>
            {/** @ts-ignore */}
            <Select
                options={selectedLocales}
                value={getDefaultLocale(localeValue)}
                onChange={(value: ValueType<SelectOption, false>, _: ActionMeta<SelectOption>) => {
                    const selectOption = value as SelectOption;
                    const locale = CountryLocales[selectOption.value as CountryName];
                    setFieldValue(fieldName, locale);
                }}
            />
        </>
    );
};
