import React from "react";

import { Flex, Header } from "Atoms";
import { CateringBoldAndLightText } from ".";

type Props = {
    refName: string;
    refPhone: string;
    refEmail: string;
    isFullWidth?: boolean;
};

export const CateringContactInformation: React.FC<Props> = ({ refName, refPhone, refEmail, isFullWidth = false }) => {
    const width = isFullWidth ? "100%" : ["100%", "100%", "50%"];
    const mr = isFullWidth ? 0 : [0, 0, 8];
    return (
        <Flex direction="column" bg="gray.200" p={4} rounded="lg" width={width} mr={mr} mb={[8, 8, 0]} height="auto">
            <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                Kontaktuppgifter
            </Header>
            <Flex direction="column" ml={2}>
                <CateringBoldAndLightText title="Ref person" value={refName} />
                <CateringBoldAndLightText title="Ref telefon" value={refPhone} />
                <CateringBoldAndLightText title="Ref mail" value={refEmail} />
            </Flex>
        </Flex>
    );
};
