import React, { useState, useEffect } from "react";

import { Alert, AlertBody, AlertTitle, AlertIcon, AlertDismissIcon } from "Molecules";
import { ScaleFade } from "Atoms";

type Props = {
    content?: string | null;
    header?: string;
    disableAutoHide?: Boolean;
    negative?: Boolean;
    callback?: () => void;
};

export const MessageNotification: React.FC<Props> = ({
    disableAutoHide = false,
    negative = false,
    header = "",
    content,
    callback,
    ...rest
}) => {
    const [hideMessage, setHideMessage] = useState(false);
    const [timer, setTimer] = useState(0);

    const handleDismiss = () => {
        setHideMessage(true);
        if (callback) {
            callback();
        }
    };

    useEffect(() => {
        if (timer == 0 && !disableAutoHide) {
            setTimer(window.setTimeout(handleDismiss, 5000));
        }
        return () => {
            window.clearTimeout(timer);
        };
    }, []);

    return (
        <ScaleFade unmountOnExit in={!hideMessage}>
            <Alert status={negative ? "error" : "success"} accent="left" {...rest}>
                <AlertIcon size="50px" />
                <AlertTitle fontSize="lg">{header}</AlertTitle>
                <AlertBody>{content}</AlertBody>
                <AlertDismissIcon onClick={handleDismiss} />
            </Alert>
        </ScaleFade>
    );
};
