import React from "react";
import { IoReload } from "@react-icons/all-files/io5/IoReload";
import { IoIosInformationCircleOutline } from "@react-icons/all-files/io/IoIosInformationCircleOutline";
import { Link as RLink } from "react-router-dom";

import { Flex, Link, Box, Text, Popup, PopupTrigger, PopupContent, PopupBody, Button } from "Atoms";
import { useLanguage } from "Providers";
import { LinkType } from "Types";

export const ReloadPage = () => {
    const { translate } = useLanguage();

    return (
        <Popup trigger="hover" closeOnBlur={false} placement="bottom-start">
            <PopupTrigger>
                <Button
                    themeColor="orange"
                    variant="outline"
                    leftIcon={IoReload}
                    onClick={() => location.reload()}
                    zIndex={50}
                    bg="gray.50"
                >
                    <Text color="orange.600">{translate("reload")}</Text>
                </Button>
            </PopupTrigger>
            <PopupContent zIndex={{ xs: 1500, lg: 25 }}>
                <PopupBody bg={"white"} textAlign="center" p={6}>
                    <Flex
                        alignItems={"flex-start"}
                        width={{ xs: "250px", lg: "400px" }}
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent={{ xs: "center", sm: "flex-start" }}
                    >
                        <Box as={IoIosInformationCircleOutline} color="blue.500" size="6rem" h="auto" />
                        <Box fontSize="lg">
                            <Text fontWeight={600}>{translate("weHaveUpdated")}</Text>

                            <Text color="gray.700">
                                {translate("pleaseRefresh")}
                                <Link as={RLink as LinkType} to="/admin/releaseNotes" color="green.600">
                                    Release notes
                                </Link>
                            </Text>
                        </Box>
                    </Flex>
                </PopupBody>
            </PopupContent>
        </Popup>
    );
};
