import React from "react";

import { LinkButton } from "Molecules";
import { Text, Box, Stack, NewTruncate as Truncate, Flex } from "Atoms";
import { BackOfficeNavigationSubChild, BackOfficeNavigationChild } from "../BackOfficeNavigation";
import { useQoplaStore } from "Stores";
import { gaProductionEvent } from "Utils";
import { GOOGLE_ANALYTICS_EVENT_ACTION, GOOGLE_ANALYTICS_EVENT_CATEGORY } from "Types";
import { Languages, useLanguage } from "Providers";

type Props = {
    isActive: boolean;
    subChildren: BackOfficeNavigationSubChild[];
    pathname: string;
} & BackOfficeNavigationChild;

export const BackOfficeNavigationItem: React.FC<Props> = ({
    icon: Icon,
    title,
    to,
    isActive,
    subChildren,
    pathname,
    ...rest
}) => {
    const { translate } = useLanguage();
    const { selectedCompany, selectedShop } = useQoplaStore();

    const getTransformedDynamicSubChildLink = (child: BackOfficeNavigationSubChild) => {
        if (child.isDynamicLink) {
            if (child.to.includes(":selectedCompanyId") && selectedCompany?.id) {
                return child.to.replace(":selectedCompanyId", selectedCompany.id);
            }
            if (child.to.includes(":selectedShopId") && selectedShop?.id) {
                return child.to.replace(":selectedShopId", selectedShop?.id);
            }
        }

        return child.to;
    };

    const getTransformedDynamicSubChildTitle = (child: BackOfficeNavigationSubChild) => {
        if (child.isDynamicLink) {
            if (child.to.includes(":selectedCompanyId") && selectedCompany?.name) {
                return selectedCompany.name;
            }
            if (child.to.includes(":selectedShopId") && selectedShop?.name) {
                return selectedShop.name;
            }
        }

        return child.title;
    };

    const addGoogleAnalyticsEvent = (isParentLink: boolean, title?: string) => {
        const analyticsLabel = !!rest?.translationKey ? translate(rest.translationKey, Languages.EN) : title;
        gaProductionEvent({
            category: GOOGLE_ANALYTICS_EVENT_CATEGORY.BACK_OFFICE,
            action: GOOGLE_ANALYTICS_EVENT_ACTION.NAVIGATE,
            label: isParentLink ? analyticsLabel : title
        });
    };

    return (
        <>
            <LinkButton
                to={to}
                fullWidth
                bg="transparent"
                _hover={{ bg: "gray.200", cursor: "pointer" }}
                px={3}
                py={2}
                rounded="lg"
                fontSize="xl"
                color="gray.900"
                fontWeight="normal"
                justifyContent="flex-start"
                data-test={`linkButton-nav-item-${title}`}
                backgroundColor={isActive ? "gray.200" : "white"}
                {...rest}
                onClick={() => addGoogleAnalyticsEvent(true)}
            >
                <Icon size="24px" />
                <Text as="span" ml={2}>
                    {title}
                </Text>
            </LinkButton>
            {!!subChildren.length &&
                subChildren.map(subChild => {
                    const title = getTransformedDynamicSubChildTitle(subChild);
                    const transformedDynamicSubChildLink = getTransformedDynamicSubChildLink(subChild);
                    const isSubChildActive = pathname == transformedDynamicSubChildLink;
                    return (
                        <LinkButton
                            key={subChild.to}
                            to={transformedDynamicSubChildLink}
                            fullWidth
                            bg="transparent"
                            _hover={{ bg: "gray.200", cursor: "pointer" }}
                            pl={6}
                            pr={3}
                            py={1}
                            rounded="lg"
                            fontSize="lg"
                            color="gray.900"
                            fontWeight="normal"
                            justifyContent="flex-start"
                            whiteSpace={"normal"}
                            data-test={`linkButton-nav-item-${title}`}
                            backgroundColor={isSubChildActive ? "gray.200" : "white"}
                            {...rest}
                            onClick={() => addGoogleAnalyticsEvent(false, title)}
                        >
                            <Stack isInline stretch={2} alignItems="center" justifyContent="flex-start">
                                <Box as={subChild.icon} size="18px"/>

                                <Truncate wrapperProps={{ width: "195px" }}>
                                    {title}
                                </Truncate>
                            </Stack>
                        </LinkButton>
                    );
                })}
        </>
    );
};
