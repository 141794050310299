import React from "react";

import { Header, Text, Box, Flex } from "Atoms";
import { SwishOrderResponse } from "../../../customer/pages/orderStatus/OrderStatusContainer";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { GLOBAL_DELIVERY_FEE_ORDER_PRODUCT_NAME } from "Constants";

type Props = {
    orderProducts: SwishOrderResponse["orderProducts"];
};

export const CateringDeliveryFee: React.FC<Props> = orderProducts => {
    const getDeliveryFee = orderProducts.orderProducts.find(
        order => order.name === GLOBAL_DELIVERY_FEE_ORDER_PRODUCT_NAME
    );
    return (
        <Flex direction="column" bg="gray.200" p={4} rounded="lg" mt={[0, 0, 8]} height="auto">
            <Box>
                <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                    {GLOBAL_DELIVERY_FEE_ORDER_PRODUCT_NAME}
                </Header>
                <Flex direction="column">
                    <Flex direction="column" height="auto" ml={4}>
                        <Text as="span" fontSize="lg">
                            {formatSwedishFinancialNumbers(getDeliveryFee ? getDeliveryFee.totalPrice : 0)}
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
};
