import { MdTouchApp } from "@react-icons/all-files/md/MdTouchApp";
import { HiOutlineCreditCard } from "@react-icons/all-files/hi/HiOutlineCreditCard";
import { BsFiles } from "@react-icons/all-files/bs/BsFiles";
import { VscDashboard } from "@react-icons/all-files/vsc/VscDashboard";
import { BsBarChart } from "@react-icons/all-files/bs/BsBarChart";
import { BsDisplay } from "@react-icons/all-files/bs/BsDisplay";
import { BiNote } from "@react-icons/all-files/bi/BiNote";
import { GrGroup } from "@react-icons/all-files/gr/GrGroup";
import { BiFoodMenu } from "@react-icons/all-files/bi/BiFoodMenu";
import { IoFlashOutline } from "@react-icons/all-files/io5/IoFlashOutline";
import { RiSuitcaseLine } from "@react-icons/all-files/ri/RiSuitcaseLine";
import { RiRestaurantLine } from "@react-icons/all-files/ri/RiRestaurantLine";
import { RiUserLine } from "@react-icons/all-files/ri/RiUserLine";
import { FiMail } from "@react-icons/all-files/fi/FiMail";
import { FiTool } from "@react-icons/all-files/fi/FiTool";
import { FiUserPlus } from "@react-icons/all-files/fi/FiUserPlus";
import { AiOutlineHistory } from "@react-icons/all-files/ai/AiOutlineHistory";
import { IoMdCash } from "@react-icons/all-files/io/IoMdCash";
import { BsPeople } from "@react-icons/all-files/bs/BsPeople";
import { BiHelpCircle } from "@react-icons/all-files/bi/BiHelpCircle";
import { FiEdit } from "@react-icons/all-files/fi/FiEdit";
import { IoFastFoodOutline } from "@react-icons/all-files/io5/IoFastFoodOutline";
import { IoExtensionPuzzleOutline } from "@react-icons/all-files/io5/IoExtensionPuzzleOutline";
import { IoCashOutline } from "@react-icons/all-files/io5/IoCashOutline";
import { IoJournalOutline } from "@react-icons/all-files/io5/IoJournalOutline";
import { IoPricetagOutline } from "@react-icons/all-files/io5/IoPricetagOutline";
import { BiImageAdd } from "@react-icons/all-files/bi/BiImageAdd";
import { BsArchive } from "@react-icons/all-files/bs/BsArchive";
import { RiChatQuoteLine } from "@react-icons/all-files/ri/RiChatQuoteLine";
import { MdAccountBalance } from "@react-icons/all-files/md/MdAccountBalance";
import { GiReceiveMoney } from "@react-icons/all-files/gi/GiReceiveMoney";
import { BiExport } from "@react-icons/all-files/bi/BiExport";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { FaMagic } from "@react-icons/all-files/fa/FaMagic";
import { FaHistory } from "@react-icons/all-files/fa/FaHistory";
import { SiCampaignmonitor } from "@react-icons/all-files/si/SiCampaignmonitor";
import { MdDeveloperBoard } from "@react-icons/all-files/md/MdDeveloperBoard";

import { SelectedShop, SelectedCompany, SelectedPos } from "Types";
import {
    isAtLeastAdmin,
    isOnlyUser,
    isQoplaSale,
    isSuperAdmin,
    isAccountingUser,
    isAtLeastCompanyAdmin,
    isShopAdmin,
    isQoplaSupport,
    isUmbrellaUser,
    isUmbrellaAdmin,
    isRootAdmin
} from "Utils";
import { withKey, and, or, is } from "FunctionUtils";
import { UserRoles, PosTypes } from "Constants";
import { BackOfficeNavigationItems } from "./BackOfficeNavigation";
import { ExpressPOSVersion } from "../../../components/pos-admin/utils/posTypes";

const withKeyUserRoles = withKey("userRoles");
const withKeyAuthenticatedUser = withKey("authenticatedUser");
const withKeyPosEnabled = withKey("posEnabled");
const withKeyOnlineEnabled = withKey("onlineEnabled");
const withKeyEnableKitchenDisplay = withKey("enableKitchenDisplay");
const withKeyEnableCatering = withKey("enableCatering");
const withKeySelectedShop = withKey("selectedShop");
const withKeySelectedPosType = withKey("selectedPosType");
const withKeySelectedCompany = withKey("selectedCompany");
const withKeyInventoryEnabled = withKey("stockTrackingEnabled");
const withKeyUmbrellaCompany = withKey("umbrellaCompany");
const withKeyFortnoxEnabled = withKey("fortnoxEnabled");
const withKeySelectedPos = withKey("selectedPos");

const isTrue = (value: boolean) => value;
const isFalse = (value: boolean) => !value;
const _isAtLeastAdmin = is(withKeyUserRoles(isAtLeastAdmin));
const _isSuperAdmin = is(withKeyUserRoles(isSuperAdmin));
const _isNotQoplaSale = is(withKeyUserRoles((roles: UserRoles[]) => !isQoplaSale(roles)));
const _isQoplaSale = is(withKeyUserRoles(isQoplaSale));
const _isQoplaSupport = is(withKeyUserRoles(isQoplaSupport));
const _isUmbrellaUser = is(withKeyUserRoles(isUmbrellaUser));
const _isNotQoplaSupport = is(withKeyUserRoles((roles: UserRoles[]) => !isQoplaSupport(roles)));
const _isAccountingUser = is(withKeyUserRoles(isAccountingUser));
const _isNotAccountingUser = is(withKeyUserRoles((roles: UserRoles[]) => !isAccountingUser(roles)));
const _isNotOnlyUser = is(withKeyUserRoles((roles: UserRoles[]) => !isOnlyUser(roles)));
const _isNotUmbrellaUser = is(withKeyUserRoles((roles: UserRoles[]) => !isUmbrellaUser(roles)));
const _isNotUmbrellaAdmin = is(withKeyUserRoles((roles: UserRoles[]) => !isUmbrellaAdmin(roles)));
const _isUmbrellaAdmin = is(withKeyUserRoles((roles: UserRoles[]) => isUmbrellaAdmin(roles)));
const _isOnlyUser = is(withKeyUserRoles(isOnlyUser));
const _isShopAdmin = is(withKeyAuthenticatedUser(isShopAdmin));
const _isRootAdmin = is(withKeyAuthenticatedUser(isRootAdmin));
const _isAtLeastCompanyAdmin = is(withKeyAuthenticatedUser(isAtLeastCompanyAdmin));
const isPosEnabled = is(withKeyPosEnabled(isTrue));
const isPosOrOnlineEnabled = or(isPosEnabled, withKeyOnlineEnabled(isTrue));
const hasEnabledCatering = or(withKeyEnableCatering(isTrue));
const hasSelectedShop = is(withKeySelectedShop((value: SelectedShop) => !!value));
const hasSelectedExpress = is(withKeySelectedPosType((value: PosTypes | null) => value === PosTypes.EXPRESS));
const hasSelectedPOS = is(withKeySelectedPosType((value: PosTypes | null) => value === PosTypes.POS));
const hasActivatedSubscriptions = is(
    withKeySelectedCompany((value: SelectedCompany) => value.settings.companySubscriptionSettings)
);
const hasSelectedCompany = is(withKeySelectedCompany((value: SelectedCompany) => !!value.id));
const isInventoryEnabled = is(withKeyInventoryEnabled(isTrue));
const isNotUmbrellaCompany = is(withKeyUmbrellaCompany(isFalse));
const isFortnoxEnabled = is(withKeyFortnoxEnabled(isTrue));
const isExpressPosV2 = is(
    withKeySelectedPos(
        (value: SelectedPos) => value.expressPOSVersion && value.expressPOSVersion === ExpressPOSVersion.V2
    )
);

//TODO: remove if not used anymore
const isChopchopOrWhiteRice = is(
    withKeySelectedCompany((value: SelectedCompany) =>
        ["5bfd540451fbe70001db7adf", "620a880a7e6d55067e5f0806"].includes(value.id)
    )
);

const navigationItems: BackOfficeNavigationItems = [
    {
        groupTitle: "Allmänt",
        translationKey: "general",
        children: [
            {
                title: "Shortcuts",
                translationKey: "shortcuts",
                to: "/admin/shortcuts",
                icon: IoFlashOutline,
                showWhen: and(or(_isOnlyUser, _isShopAdmin, _isRootAdmin), _isNotUmbrellaUser, _isNotUmbrellaAdmin)
            },
            {
                title: "Företag",
                translationKey: "company",
                to: "/admin/companies",
                icon: RiSuitcaseLine,
                showWhen: or(and(_isNotOnlyUser, _isNotQoplaSale, _isNotAccountingUser), _isAtLeastCompanyAdmin),
                subChildren: [
                    {
                        title: "Valt företag",
                        to: "/admin/company/:selectedCompanyId",
                        icon: FiSettings,
                        showWhen: and(hasSelectedCompany, _isAtLeastCompanyAdmin),
                        isDynamicLink: true
                    }
                ]
            },
            {
                title: "Restauranger",
                translationKey: "restaurants",
                to: "/admin/shops",
                icon: RiRestaurantLine,
                showWhen: _isNotQoplaSale,
                subChildren: [
                    {
                        title: "Valt shop",
                        to: "/admin/shop/:selectedShopId",
                        icon: FiSettings,
                        showWhen: and(hasSelectedCompany, hasSelectedShop, _isAtLeastCompanyAdmin),
                        isDynamicLink: true
                    }
                ]
            },
            {
                title: "Lager",
                translationKey: "stock",
                to: "/admin/inventory",
                icon: BsArchive,
                showWhen: and(_isAtLeastAdmin, isInventoryEnabled)
            },
            {
                title: "Användare",
                translationKey: "users",
                to: "/admin/users",
                icon: RiUserLine,
                showWhen: _isAtLeastAdmin
            },
            {
                title: "Kundfeedback",
                translationKey: "customerFeedback",
                to: "/admin/customerFeedback",
                icon: RiChatQuoteLine,
                showWhen: or(_isAtLeastAdmin, _isAccountingUser)
            }
        ]
    },
    {
        groupTitle: "Hjälp",
        translationKey: "help",
        children: [
            {
                icon: BiHelpCircle,
                title: "Hjälp & manual",
                translationKey: "helpDocuments",
                to: "/admin/manual"
            },
            {
                icon: BiNote,
                title: "Release notes",
                translationKey: "releaseNotes",
                to: "/admin/releaseNotes"
            }
        ]
    },
    {
        groupTitle: "Artiklar",
        translationKey: "articles",
        showWhen: and(_isAtLeastAdmin, _isNotUmbrellaUser),
        children: [
            {
                title: "Produkter",
                translationKey: "products",
                to: "/admin/products",
                icon: IoFastFoodOutline
            },
            {
                title: "Menyer",
                translationKey: "menus",
                to: "/admin/menus",
                icon: BiFoodMenu
            },
            {
                title: "Tillvalsgrupper",
                translationKey: "addonGroups",
                to: "/admin/ingredients",
                icon: IoExtensionPuzzleOutline
            },
            {
                title: "Mediabibliotek",
                translationKey: "mediaLibrary",
                to: "/admin/mediaLibrary",
                icon: BiImageAdd
            }
        ]
    },
    {
        groupTitle: "Beställningsskärmar",
        translationKey: "orderDisplays",
        showWhen: and(hasSelectedShop, isPosOrOnlineEnabled, _isNotQoplaSale, _isNotUmbrellaUser, _isNotUmbrellaAdmin),
        children: [
            {
                title: "Orderskärm",
                translationKey: "orderDisplay",
                to: "/admin/orderDisplay",
                icon: BsDisplay,
                showWhen: _isSuperAdmin
            },
            {
                title: "Köksskärm",
                translationKey: "kitchenDisplay",
                to: "/admin/kitchenDisplay",
                icon: BiFoodMenu,
                showWhen: and(withKeyEnableKitchenDisplay(isTrue))
            },
            {
                title: "Upphämtningsskärm",
                translationKey: "pickupDisplay",
                to: "/admin/pickupDisplay",
                icon: BsPeople
            }
        ]
    },
    {
        groupTitle: "Kassor",
        translationKey: "cashRegisters",
        showWhen: and(isPosEnabled, or(hasSelectedPOS, hasSelectedExpress)),
        children: [
            {
                title: "Kassa",
                translationKey: "cashRegister",
                to: "/admin/pos",
                icon: IoCashOutline,
                showWhen: hasSelectedPOS
            },
            {
                title: "Expresskassa",
                translationKey: "expressRegister",
                to: "/admin/expressPosContainer",
                icon: BiFoodMenu,
                showWhen: hasSelectedExpress
            },
            {
                title: "Expresskassor V.2",
                translationKey: "expressRegister2",
                to: "/admin/ExpressLandingPage",
                icon: BiFoodMenu,
                showWhen: and(hasSelectedExpress, or(_isSuperAdmin, isExpressPosV2))
            }
        ]
    },
    {
        groupTitle: "Catering",
        translationKey: "cateringNav",
        showWhen: and(hasEnabledCatering, _isAtLeastAdmin, _isNotUmbrellaUser, _isNotUmbrellaAdmin),
        children: [
            {
                title: "Ordrar",
                translationKey: "orders",
                to: "/admin/view-all-catering-orders",
                icon: IoCashOutline
            }
        ]
    },
    {
        groupTitle: "Statistik",
        translationKey: "statistics",
        showWhen: and(or(_isAtLeastAdmin, _isAccountingUser, _isUmbrellaUser), or(isPosOrOnlineEnabled)),
        children: [
            {
                title: "Försäljningsöversikt",
                translationKey: "salesOverview",
                to: "/admin/analyticsDashboard",
                icon: BsBarChart,
                showWhen: _isNotQoplaSupport
            },
            {
                title: "Försäljningsrapporter",
                translationKey: "salesReports",
                to: "/admin/reportDashboard",
                icon: VscDashboard,
                showWhen: _isNotQoplaSupport
            },
            {
                title: "Orderhistorik",
                translationKey: "orderHistory",
                to: "/admin/receiptCopies",
                icon: AiOutlineHistory
            },
            {
                title: "Betalningsunderlag",
                translationKey: "onlineInvoices",
                to: "/admin/onlineInvoices",
                icon: IoCashOutline
            }
        ]
    },
    {
        groupTitle: "X/Z Rapport",
        translationKey: "xzReports",
        showWhen: and(
            or(_isAtLeastAdmin, _isAccountingUser),
            or(isPosOrOnlineEnabled),
            and(_isNotUmbrellaUser),
            and(_isNotUmbrellaAdmin)
        ),
        children: [
            {
                title: "Z-Rapport",
                translationKey: "zReport",
                to: "/admin/report/Z",
                icon: BsFiles
            },
            {
                title: "X-Rapport",
                translationKey: "xReport",
                to: "/admin/report/X",
                icon: BsFiles
            },
            {
                title: "Journalminne",
                translationKey: "registerJournal",
                to: "/admin/journal",
                icon: IoJournalOutline
            },
            {
                title: "Kontantfakturor",
                translationKey: "cashInvoices",
                to: "/admin/reportCashInvoice",
                icon: IoMdCash,
                showWhen: isPosEnabled
            },
            {
                title: "Fortnox",
                to: "/admin/fortnoxRegister",
                icon: MdAccountBalance,
                showWhen: and(or(_isAccountingUser, _isAtLeastCompanyAdmin), and(isFortnoxEnabled))
            }
        ]
    },
    {
        groupTitle: "Kassainställningar",
        translationKey: "cashRegisterSettings",
        showWhen: and(isPosEnabled, _isNotAccountingUser, _isAtLeastAdmin),
        children: [
            {
                title: "Kortterminaler",
                translationKey: "cardTerminals",
                to: "/admin/terminalAdmin",
                icon: HiOutlineCreditCard
            },
            {
                title: "Kassor",
                translationKey: "cashRegisters",
                to: "/admin/posAdmin",
                icon: IoCashOutline
            },
            {
                title: "Expresskassor",
                translationKey: "expressRegisters",
                to: "/admin/expressAdmin",
                icon: MdTouchApp
            }
        ]
    },
    {
        groupTitle: "Faktura",
        translationKey: "invoices",
        showWhen: and(
            or(_isAtLeastAdmin, _isAccountingUser),
            or(isPosOrOnlineEnabled),
            and(_isNotUmbrellaUser),
            and(_isNotUmbrellaAdmin)
        ),
        children: [
            {
                title: "Fakturakunder",
                translationKey: "invoiceCustomers",
                to: "/admin/invoiceCustomers",
                icon: HiOutlineCreditCard
            },
            {
                title: "Fakturaunderlag",
                translationKey: "customerInvoices",
                to: "/admin/invoices",
                icon: IoMdCash
            }
        ]
    },
    {
        groupTitle: "Rabatter",
        translationKey: "discountsAndCampaigns",
        showWhen: and(
            _isNotAccountingUser,
            or(_isAtLeastAdmin, _isAccountingUser),
            or(isPosOrOnlineEnabled),
            and(_isNotUmbrellaUser),
            or(and(_isUmbrellaAdmin, isNotUmbrellaCompany), and(_isNotUmbrellaAdmin))
        ),
        children: [
            {
                title: "Rabatter",
                translationKey: "discounts",
                to: "/admin/discountAdmin/discounts",
                icon: IoPricetagOutline
            },
            {
                title: "Stående rabatter",
                translationKey: "fixedDiscounts",
                to: "/admin/discountAdmin/fixedDiscounts",
                icon: IoPricetagOutline
            },
            {
                title: "Komborabatter",
                translationKey: "bundleDiscounts",
                to: "/admin/discountCombos",
                icon: IoPricetagOutline
            },
            {
                title: "Campaign Tool",
                translationKey: "campaignTool",
                to: "/admin/campaignAdmin",
                icon: SiCampaignmonitor
            }
        ]
    },
    {
        groupTitle: "Super admin",
        translationKey: "superAdmin",
        showWhen: or(_isSuperAdmin, _isQoplaSale, _isQoplaSupport),
        children: [
            {
                title: "Verktyg",
                translationKey: "tools",
                to: "/admin/superAdmin",
                icon: FiTool
            },
            {
                title: "Service Fee",
                translationKey: "serviceFees",
                to: "/admin/serviceFeeAdmin",
                icon: GiReceiveMoney
            },
            {
                title: "Customer Registration",
                translationKey: "customerRegistration",
                to: "/admin/customerRegistrationAdmin",
                icon: FiUserPlus
            },
            {
                title: "Staff Management Logs",
                translationKey: "staffManagementLogs",
                to: "/admin/staffManagementLogs",
                icon: BiExport,
                showWhen: or(_isSuperAdmin)
            },
            {
                title: "Gruppsidor",
                translationKey: "groupPages",
                to: "/admin/groupLandingPageAdmin",
                icon: GrGroup,
                showWhen: _isNotQoplaSale
            },
            {
                title: "Release notes",
                translationKey: "releaseNotes",
                to: "/admin/changelog",
                icon: FiEdit
            }
        ]
    },
    {
        groupTitle: "AI Content",
        translationKey: "AIContentAssistant",
        showWhen: or(_isSuperAdmin, _isQoplaSale, _isQoplaSupport),
        children: [
            {
                title: "AI Assistant",
                to: "/admin/AIContentAssistantAdmin",
                showWhen: or(_isSuperAdmin, _isQoplaSale, _isQoplaSupport),
                icon: FaMagic
            },
            {
                title: "AI History",
                to: "/admin/AIContentAssistantAdmin/history",
                showWhen: or(_isSuperAdmin, _isQoplaSale, _isQoplaSupport),
                icon: FaHistory
            }
        ]
    },
    {
        groupTitle: "Abonnemang",
        showWhen: and(hasActivatedSubscriptions, _isNotUmbrellaAdmin, or(_isAtLeastCompanyAdmin, _isQoplaSupport)),
        translationKey: "subscriptions",
        children: [
            {
                icon: FiMail,
                translationKey: "subscriptions",
                title: "Abonnemang",
                to: "/admin/subscriptions"
            },
            {
                icon: AiOutlineHistory,
                title: "Abonnemangshistorik",
                translationKey: "subscriptionHistory",
                to: "/admin/subscriptions/overview"
            },
            {
                icon: FiMail,
                translationKey: "groupSubscriptions",
                title: "Gruppabonnemang",
                to: "/admin/groupSubscriptions"
            }
        ]
    },
    {
        groupTitle: "Dev",
        showWhen: or(_isSuperAdmin),
        translationKey: "dev",
        children: [
            {
                icon: MdDeveloperBoard,
                translationKey: "components",
                title: "Components",
                to: "/admin/components"
            }
        ]
    }
];

export default navigationItems;
