import React from "react";

import { Box, Flex } from "Atoms";
import { CateringContactInformation, CateringCompanyInformation, CateringProducts } from ".";
import { SwishOrderResponse } from "../../../customer/pages/orderStatus/OrderStatusContainer";

type Props = {
    order: SwishOrderResponse;
    displayTotalPrice?: boolean;
    canSelectPrices?: boolean;
};

export const CateringCompanySelfPickup: React.FC<Props> = ({ order, displayTotalPrice, canSelectPrices = true }) => {
    const {
        userInformation: { onlineContactInformation },
        orderProducts,
        invoiceData
    } = order;
    const refPhone = onlineContactInformation.phoneNumber2 || onlineContactInformation.phoneNumber;

    return (
        <Box>
            <Flex direction="column">
                <Flex justify="space-between" direction={["column", "column", "row"]}>
                    <CateringContactInformation
                        refName={onlineContactInformation.name}
                        refPhone={refPhone}
                        refEmail={onlineContactInformation.email}
                    />
                    <CateringCompanyInformation invoiceData={invoiceData} phoneNumber={refPhone} />
                </Flex>
                <CateringProducts
                    orderProducts={orderProducts}
                    displayTotalPrice={displayTotalPrice}
                    canSelectPrices={canSelectPrices}
                />
            </Flex>
        </Box>
    );
};
