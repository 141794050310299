import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useLocalStorage, useMothershipQuery } from "Hooks";
import { IQoplaContext, useQopla } from "Providers";
import { useQoplaStore, usePosStore } from "Stores";
import { Stack } from "Atoms";
import backOfficeNavigationItems from "../backOfficeNavigationItems";
import { getBackOfficeNavigationItems } from "../utils/backOfficeNavigationUtils";
import { BackOfficeNavigationGroup, BackOfficeNavigationForm } from "./";
import { UserRoles } from "Constants";
import { GET_UNHANDLED_INVOICE_NUMBER } from "GraphQLQueries";
import { isAccountingUser, isAtLeastAdmin } from "Utils";
import { ThirdPartyAccountingType } from "Types";

export type BackOfficeSideNavigationValidity = {
    onlineEnabled: boolean;
    posEnabled: boolean;
    fortnoxEnabled: boolean;
    enableKitchenDisplay: boolean;
    enableCatering: boolean;
    userRoles: UserRoles[] | [];
    stockTrackingEnabled: boolean;
    umbrellaCompany: boolean;
} & IQoplaContext;

type Props = {
    onCloseEditMode: () => void;
    isInEditMode: boolean;
};

export const BackOfficeNavigationBody: React.FC<Props> = ({ isInEditMode, onCloseEditMode, ...rest }) => {
    const [openGroupTitles, setOpenGroupTitles] = useState<string[]>(() => {
        const item = window.sessionStorage.getItem("QAdminSideNavigationOpenTitles");
        if (item) {
            return JSON.parse(item);
        } else {
            return ["Allmänt"];
        }
    });

    const [navigationSortOrder, setNavigationSortOrder] = useLocalStorage("QAdminSideNavigationOrder", []);

    const { pathname } = useLocation();

    const qopla = useQopla();
    const { selectedCompany, selectedShop } = useQoplaStore();
    const { selectedPos, selectedTerminal, selectedPosType } = usePosStore();

    const _isAtLeastAdmin = isAtLeastAdmin(qopla.authenticatedUser.roles);
    const _isAccountingUser = isAccountingUser(qopla.authenticatedUser.roles);
    const haveAccessToInvoices = _isAtLeastAdmin || _isAccountingUser;

    const { data: getInvoices } = useMothershipQuery(GET_UNHANDLED_INVOICE_NUMBER, {
        variables: { shopId: selectedShop?.id ?? "" },
        skip: !selectedShop?.id || !haveAccessToInvoices
    });

    const isFortnoxEnabled =
        selectedCompany?.settings?.thirdPartyAccountingAccessType === ThirdPartyAccountingType.FORTNOX;

    const backOfficeNavigationValidity: BackOfficeSideNavigationValidity = {
        ...qopla,
        userRoles: qopla.authenticatedUser.roles,
        selectedCompany,
        selectedShop,
        selectedPos,
        selectedTerminal,
        selectedPosType,
        onlineEnabled: selectedCompany?.settings?.onlineEnabled ?? false,
        posEnabled: selectedCompany?.settings?.posEnabled ?? false,
        fortnoxEnabled: isFortnoxEnabled,
        enableKitchenDisplay: selectedShop?.settings?.enableKitchenDisplay ?? false,
        enableCatering: selectedShop?.settings?.cateringSettings?.enableCatering ?? false,
        stockTrackingEnabled: selectedCompany?.settings?.stockTrackingEnabled ?? false,
        umbrellaCompany: selectedCompany.umbrellaCompany ?? false
    };

    const onSetNavigationItemOrder = (orderedNavigationItems: string[]) => {
        setNavigationSortOrder(orderedNavigationItems);
        onCloseEditMode();
    };

    const _backOfficeNavigationItems = getBackOfficeNavigationItems(
        backOfficeNavigationItems,
        backOfficeNavigationValidity,
        navigationSortOrder
    );

    const onToggleOpenNavigationGroup = (groupTitle: string) => {
        const isExpanded = openGroupTitles.includes(groupTitle);

        setOpenGroupTitles(currOpenGroupTitles => {
            if (isExpanded) {
                const _openGroupTitles = currOpenGroupTitles.filter(openGroupTitle => openGroupTitle !== groupTitle);
                window.sessionStorage.setItem("QAdminSideNavigationOpenTitles", JSON.stringify(_openGroupTitles));

                return _openGroupTitles;
            } else {
                const _openGroupTitles = currOpenGroupTitles.concat(groupTitle);
                window.sessionStorage.setItem("QAdminSideNavigationOpenTitles", JSON.stringify(_openGroupTitles));

                return _openGroupTitles;
            }
        });
    };

    return (
        <Stack flex="1" overflow="hidden auto" align="flex-start" stretch={8} pr={[0, 4]} {...rest}>
            {isInEditMode ? (
                <BackOfficeNavigationForm
                    backOfficeNavigationItems={_backOfficeNavigationItems}
                    onSetNavigationItemOrder={onSetNavigationItemOrder}
                />
            ) : (
                _backOfficeNavigationItems.map(backOfficeNavigationItem => {
                    const isExpanded =
                        openGroupTitles.includes(backOfficeNavigationItem.groupTitle) ||
                        !!backOfficeNavigationItem.children.find(child => child.to === pathname);

                    return (
                        <BackOfficeNavigationGroup
                            key={backOfficeNavigationItem.groupTitle}
                            backOfficeNavigationItem={backOfficeNavigationItem}
                            isExpanded={isExpanded}
                            unhandledInvoiceNumber={getInvoices?.getUnhandledInvoiceNumber ?? 0}
                            onToggleOpenNavigationGroup={onToggleOpenNavigationGroup}
                        />
                    );
                })
            )}
        </Stack>
    );
};
