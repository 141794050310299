import React from "react";
import { FieldArray, Form, Formik } from "formik-next";
import { ReactSortable } from "react-sortablejs";
import { GoThreeBars } from "@react-icons/all-files/go/GoThreeBars";

import { Box, Button, Header, PseudoBox, Text } from "Atoms";
import { BackOfficeNavigationItems } from "../BackOfficeNavigation";

type Props = {
    backOfficeNavigationItems: BackOfficeNavigationItems;
    onSetNavigationItemOrder: (orderedNavigationItems: string[]) => void;
};

export const BackOfficeNavigationForm: React.FC<Props> = ({ backOfficeNavigationItems, onSetNavigationItemOrder }) => {
    const navigationItemTitles = backOfficeNavigationItems.map(navigation => navigation.groupTitle);

    return (
        <Box w="full">
            <Header size="md" mb={6}>
                Ändra ordning
            </Header>
            <Formik
                initialValues={{
                    options: navigationItemTitles
                }}
                onSubmit={values => onSetNavigationItemOrder(values.options)}
            >
                {({ values }) => (
                    <Form style={{ width: "100%" }}>
                        <FieldArray
                            name="options"
                            render={({ form }) => (
                                <ReactSortable
                                    list={navigationItemTitles.map(option => ({
                                        id: option,
                                        name: option
                                    }))}
                                    setList={newList => {
                                        form.setFieldValue(
                                            "options",
                                            values.options.sort(
                                                (a, b) =>
                                                    newList.findIndex(i => i.id === a) -
                                                    newList.findIndex(i => i.id === b)
                                            )
                                        );
                                    }}
                                >
                                    {values.options.map((option, i) => (
                                        <PseudoBox
                                            key={option}
                                            date-id={option}
                                            h="auto"
                                            bg="white"
                                            alignItems="center"
                                            display="flex"
                                            py={2}
                                            px={3}
                                            _hover={{ cursor: "grab" }}
                                            rounded="lg"
                                        >
                                            <PseudoBox as={GoThreeBars} size="18px" color="gray.800" mr={2} />
                                            <Text as="span" fontSize="lg">
                                                {option}
                                            </Text>
                                        </PseudoBox>
                                    ))}
                                </ReactSortable>
                            )}
                        />
                        <Box position="sticky" bottom="0px" bg="white">
                            <Button type="submit" themeColor="blue" fullWidth mt={6}>
                                Spara
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
