import React from "react";

import { Header, Text, Box, Flex } from "Atoms";
import { PaymentMethodAmount } from "Types";
import { SwedishCateringPaymentMethod } from "Constants";

type Props = {
    paymentMethodAmounts: PaymentMethodAmount[];
};

export const CateringPayment: React.FC<Props> = ({ paymentMethodAmounts }) => {
    const paymentMethod = paymentMethodAmounts.map(({ paymentMethod }) => {
        const keyName = paymentMethod as keyof typeof SwedishCateringPaymentMethod;
        return keyName ? SwedishCateringPaymentMethod[keyName] : paymentMethod;
    }).join(", ");
    return (
        <Flex direction="column" bg="gray.200" p={4} rounded="lg" mt={[0, 0, 8]} height="auto">
            <Box>
                <Header size="lg" as="h3" fontWeight="normal" mb={6}>
                    Betalning
                </Header>
                <Flex direction="column">
                    <Flex direction="column" height="auto" ml={4}>
                        <Text as="span" fontSize="lg">
                            {paymentMethod}
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
};
